import React from 'react';

function CourseWebsite() {
    return (
        <div>
            <h1>网站正在建设中</h1>
            <p>需要感情课程请加微信：qingganlm6。</p>
        </div>
    );
}

export default CourseWebsite;